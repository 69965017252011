import { ArrowBack } from "@mui/icons-material";
import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommandForm from "../../components/Device/CommandForm";
import ConfigurationForm from "../../components/Device/ConfigurationForm";
import { useGetAllDevicesQuery } from "../../features/api/apiSlice";
import { IDevice } from "../../models/IDevice";

export default function DeviceConfigPage() {
    const navigate = useNavigate();
    const { deviceId } = useParams();

    const { data: devices, isFetching } = useGetAllDevicesQuery();
    const device: IDevice = devices?.filter(x => x.id === deviceId)[0];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Grid container display="flex" spacing={1} direction="row" alignItems="center" justifyContent="center" width='100%' sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={9} sx={{ ml: 1 }} >
                    <Stack direction="row">
                        <IconButton onClick={() => navigate("/devices")}>
                            <ArrowBack htmlColor="#000000"></ArrowBack>
                        </IconButton>
                        <Typography variant="h4">
                            {device?.name}
                        </Typography>
                    </Stack>
                </Grid>
                {(device?.commands?.length > 0) &&
                    <>
                        <Grid xs={12} sm={12} md={9} sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="h5" fontWeight="bold">Commands</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} sx={{ ml: 1, mb: 2 }} >
                            {
                                device.commands.map(command =>
                                    <CommandForm key={command.id} deviceId={device.id} ownerId={device.ownerId} command={command} />
                                )
                            }
                        </Grid>
                    </>
                }
                {device?.configuration?.length > 0 &&
                    <>
                        <Grid xs={12} sm={12} md={9} sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="h5" fontWeight="bold">Configuration</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={9} sx={{ ml: 1, mb: 2 }} >
                            {
                                device.configuration.map(configCommand =>
                                    <ConfigurationForm key={configCommand.id} deviceId={device.id} ownerId={device.ownerId} command={configCommand} />
                                )
                            }
                        </Grid>
                    </>
                }
            </Grid >

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}