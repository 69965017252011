import { Backdrop, Button, Grid, IconButton, Stack, TextField, CircularProgress, Typography } from "@mui/material";
import Gateway from "../../components/Gateway/Gateway";
import { GatewayRegion, IGateway } from "../../models/IGateway";
import { SearchOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import GenericDeleteDialog from "../../components/Dialogs/GenericDeleteDialog";
import { IAddGatewayInfo } from "../../models/responses/gateways/IAddGatewayResponse";
import AddGatewayDialog from "../../components/Dialogs/Gateways/AddGatewayDialog";
import NewGatewayDataDialog from "../../components/Dialogs/Gateways/NewGatewayDataDialog";
import UpdateGatewayDialog from "../../components/Dialogs/Gateways/UpdateGatewayDialog";
import { IUser, UserRole } from "../../models/IUser";
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from "../../features/auth/authSlice";
import { useAddGatewayMutation, useDeleteGatewayMutation, useGetAllGatewaysQuery, useGetAllUsersQuery, useUpdateGatewayMutation } from "../../features/api/apiSlice";
import { IAddGatewayRequest } from "../../models/requests/gateways/IAddGatewayRequest";
import { IUpdateGatewayRequest } from "../../models/requests/gateways/IUpdateGatewayRequest";
import { IDeleteGatewayRequest } from "../../models/requests/gateways/IDeleteGatewayRequest";

export default function GatewaysPage() {
  const authUser = useAppSelector(selectCurrentUser);

  const [filter, setFilter] = useState<string>("");
  const [selectableUsers, setSelectableUsers] = useState<IUser[]>([]);

  const { data: users } = useGetAllUsersQuery();
  const { data: gateways, isLoading, isFetching } = useGetAllGatewaysQuery();
  
  const [addGateway, addGatewayResult] = useAddGatewayMutation();
  const [updateGateway, updateGatewayResult] = useUpdateGatewayMutation();
  const [deleteGateway, deleteGatewayResult] = useDeleteGatewayMutation();

  const [newGatewayInfo, setNewGatewayInfo] = useState<IAddGatewayInfo | null>(null);

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handleAddGateway = async (gatewayEui: string, gatewayName: string, gatewayDescription: string | null, gatewayRegion: GatewayRegion, ownerId: string | null) => {
    setOpenAddDialog(false);

    const request: IAddGatewayRequest = {
      gatewayEui: gatewayEui,
      name: gatewayName,
      description: gatewayDescription,
      region: gatewayRegion,
      ownerId: ownerId
    };

    const result = await addGateway(request).unwrap();

    setNewGatewayInfo(result.data);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
  const [selectedGateway, setSelectedGateway] = useState<IGateway | null>(null);

  const handleFilterChange: (event: any) => void = (event) => {
    setFilter(event.target.value);
  };

  const handleEditClick = (gateway: IGateway) => {
    setSelectedGateway(gateway);
    setOpenUpdateDialog(true);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    setSelectedGateway(null);
  };

  const handleUpdateGateway = (gatewayId: string, gatewayName: string, gatewayDescription: string) => {
    setOpenUpdateDialog(false);

    const request: IUpdateGatewayRequest = {
      name: gatewayName,
      description: gatewayDescription,
      ownerId: selectedGateway.ownerId
    };
    updateGateway({gatewayId, updateGateway: request});
  };

  const handleDeleteClick = (gateway: IGateway) => {
    setSelectedGateway(gateway);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedGateway(null);
  };

  const handleDeleteGateway = (gatewayId: string) => {
    setOpenDeleteDialog(false);
    const request: IDeleteGatewayRequest = {
      ownerId: selectedGateway.ownerId
    };
    deleteGateway({gatewayId, deleteGateway: request});
  };

  useEffect(() => {
    if (authUser.role === UserRole.SuperAdmin && users) {
      setSelectableUsers([...users].filter(u => u.role === UserRole.Admin))
    }
  }, [users, authUser.role]);

  return (
    <>
      <Grid container display="flex" spacing={1} direction="row" alignItems="center" justifyContent="center" width='100%' sx={{ mt: 2 }}>
        <Grid item xs={12} sm={12} md={9} sx={{ ml: 1 }} >
            <Typography variant="h4">
              Gateways
            </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={9} sx={{ ml: 1 }} >
          <Stack direction="row">
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="Search filter"
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
                ),
              }}
              value={filter}
              onChange={handleFilterChange}
            />
            {authUser.role !== UserRole.User &&
              <Button variant="contained" onClick={() => setOpenAddDialog(true)} sx={{ ml: 1, mt: 1, mb: 1 }}>Add Gateway</Button>
            }
          </Stack>
        </Grid>
        <>
          {gateways?.length === 0 &&
            <Grid item display="flex" xs={12} minHeight="70vh" alignItems="center" justifyContent="center">
              <Typography variant="h6">
                You don't have any gateways
              </Typography>
            </Grid>
          }
          {gateways?.length > 0 &&
            gateways
              .filter((gw) => gw.name.includes(filter) ||
                gw.description.includes(filter) ||
                gw.eui.includes(filter) ||
                gw.id.includes(filter))
              .map((gw) =>
                <Grid key={gw.id} item xs={12} sm={8} md={6} lg={5} xl={4} sx={{ ml: 1 }}>
                  <Gateway gateway={gw} showActions={authUser.role !== UserRole.User} onEditClick={() => handleEditClick(gw)} onDeleteClick={() => handleDeleteClick(gw)} />
                </Grid>
              )
          }
        </>
      </Grid >

      {openAddDialog && <AddGatewayDialog selectableUsers={selectableUsers} openDialog={openAddDialog} handleClose={() => setOpenAddDialog(false)} handleAddGateway={handleAddGateway} />}
      {newGatewayInfo && <NewGatewayDataDialog openDialog={true} handleClose={() => { setNewGatewayInfo(null); }} gatewayInfo={newGatewayInfo} />}

      {openUpdateDialog && <UpdateGatewayDialog openDialog={openUpdateDialog} handleClose={handleCloseUpdateDialog} gateway={selectedGateway} handleUpdateGateway={handleUpdateGateway} />}

      {openDeleteDialog &&
        <GenericDeleteDialog
          openDialog={openDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          title={`Do you want to delete ${selectedGateway.name} widh Id ${selectedGateway.id} ?`}
          message="This action cannot be undone and you will need to create and configure the Gateway again."
          handleDelete={() => handleDeleteGateway(selectedGateway.id)}
        />
      }
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || (isFetching && gateways?.length === 0) || addGatewayResult.isLoading || updateGatewayResult.isLoading || deleteGatewayResult.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}