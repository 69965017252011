import { TextField, Box, Typography, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ILoraCommand } from "../../models/ISensor";
import SendIcon from '@mui/icons-material/Send';
import { useSendCommandMutation } from "../../features/api/apiSlice";
import { LoadingButton } from "@mui/lab";
import { ISendCommandRequest } from "../../models/requests/devices/ISendCommandRequest";
import { useEffect } from "react";
import { toast } from "react-toastify";

type CommandFormProps = {
    deviceId: string;
    ownerId?: string | null;
    command: ILoraCommand;
}

const CommandForm = ({ deviceId, ownerId, command }: CommandFormProps) => {
    const { handleSubmit, control } = useForm();
    const [sendCommand, sendCommandResult] = useSendCommandMutation();

    useEffect(() => {
        if (sendCommandResult.isSuccess) {
            toast("Success sending command " + command.name, {type: "success"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendCommandResult.isSuccess]);

    useEffect(() => {
        if (sendCommandResult.isError) {
            toast("Error sending command " + command.name, {type:"error"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendCommandResult.isError])

    function onSubmit(data: any) {
        if (!sendCommandResult.isLoading) {
            const request: ISendCommandRequest = {
                ownerId: ownerId,
                command: {
                    id: command.id,
                    name: command.name,
                    params: command.params?.length > 0 ?
                        command.params.map((param) => {
                            return { ...param, value: data[param.id] }
                        })
                        :
                        []
                }
            };
            sendCommand({ deviceId: deviceId, sendCommand: request });
        }
    }

    if (!command?.params || command?.params?.length === 0) {
        return (
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <Typography variant="h6">{command.name}</Typography>
                <LoadingButton
                    loading={sendCommandResult.isLoading}
                    onClick={() => onSubmit(null)}
                    variant="outlined"
                    endIcon={<SendIcon />}
                    loadingPosition="end"
                    sx={{ justifyContent: "end" }}>
                    Send
                </LoadingButton>
            </Stack>
        )
    } else {
        return (
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h6">{command.name}</Typography>
                {command.params.map(param =>
                    <Controller
                        key={param.id}
                        name={param.id}
                        control={control}
                        defaultValue={param.value}
                        rules={{
                            required: "This field is required",
                            min: param.minValue,
                            max: param.maxValue
                        }}
                        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                            <TextField
                                autoFocus
                                margin="normal"
                                label={param.name}
                                type="text"
                                fullWidth
                                variant="outlined"
                                required
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error ? error.message : ""}
                            />
                        )}
                    />
                )}

                <Box display="flex" justifyContent="end">
                    <LoadingButton
                        loading={sendCommandResult.isLoading}
                        type="submit"
                        variant="outlined"
                        endIcon={<SendIcon />}
                        loadingPosition="end"
                        sx={{ justifyContent: "end" }}>
                        Send
                    </LoadingButton>
                </Box>
            </form>
        );
    }
}

export default CommandForm;