import CssBaseline from '@mui/material/CssBaseline';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import { store } from './app/store';
import PrivateRoute, { PrivateRouteProps } from './components/PrivateRoute/PrivateRoute';
import DevicesPage from './pages/Devices/DevicesPage';
import SensorConfigPage from './pages/Devices/SensorConfigPage';
import SensorHistoryPage from './pages/Devices/SensorHistoryPage';
import GatewaysPage from './pages/Gateways/GatewaysPage';
import LoginPage from './pages/Login/LoginPage';
import ProfilePage from './pages/Profile/ProfilePage';
import UsersPage from './pages/Users/UsersPage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import DeviceConfigPage from './pages/Devices/DeviceConfigPage';

const theme = createTheme();

const defaultProtectedRouteProps: Omit<PrivateRouteProps, 'outlet'> = {
  authenticationPath: '/login',
};

function App() {
  useEffect(() => {
    window.addEventListener('unhandledrejection', function (event) {
      event.preventDefault();
    });
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer theme='colored'/>
        <Router>
          <Routes>
            <Route path='gateways' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<GatewaysPage />} />} />
            <Route path='devices' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<DevicesPage />} />} />
            <Route path='devices/:deviceId/configuration' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<DeviceConfigPage />} />} />
            <Route path='devices/:deviceId/:sensorId/history' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<SensorHistoryPage />} />} />
            <Route path='devices/:deviceId/:sensorId/configuration' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<SensorConfigPage />} />} />
            <Route path='users' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<UsersPage />} />} />
            <Route path='profile' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<ProfilePage />} />} />
            <Route path='login' element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/gateways" replace />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
