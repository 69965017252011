import { Stack, TextField, CircularProgress, Typography, IconButton } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IDevice } from "../../models/IDevice";
import { ArrowBack } from "@mui/icons-material";
import { ISensor } from "../../models/ISensor";
import { useGetAllDevicesQuery, useGetSensorHistoricDataQuery } from "../../features/api/apiSlice";

export default function SensorHistoryPage() {
    const navigate = useNavigate();
    const { deviceId, sensorId } = useParams();

    const {data: devices} = useGetAllDevicesQuery();
    
    const device: IDevice = devices?.filter(x => x.id === deviceId)[0];
    const sensor: ISensor = device?.sensors.filter(x => x.id === sensorId)[0];

    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const {data, isFetching} = useGetSensorHistoricDataQuery({deviceId, sensorId, fromDate: fromDate?.getTime(), toDate: toDate?.getTime()});

    const columns: GridColDef[] = [
        {
            field: 'timestamp',
            headerName: 'Timestamp',
            type: "dateTime",
            width: 250
        },
        {
            field: 'reading',
            headerName: 'Reading',
            width: 150
        },
        {
            field: 'value',
            headerName: 'Value',
            width: 150,
        }
    ];

    return (
        <>
            <Grid container display="flex" spacing={1} direction="row" alignItems="center" justifyContent="center" width='100%' sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={9} sx={{ ml: 1 }} >
                    <Stack direction="row">
                        <IconButton onClick={() => navigate("/devices")}>
                            <ArrowBack htmlColor="#000000"></ArrowBack>
                        </IconButton>
                        <Typography variant="h4">
                            {device?.name} ({sensor?.name})
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={9} sx={{ ml: 1 }} >
                    <Stack direction="row" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="From Date"
                                value={fromDate}
                                onChange={(newValue) => {
                                    setFromDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DatePicker
                                label="To Date"
                                value={toDate}
                                onChange={(newValue) => {
                                    setToDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Stack>
                </Grid>
                <>
                    {data?.length === 0 &&
                        <Grid item display="flex" xs={12} minHeight="70vh" alignItems="center" justifyContent="center">
                            <Typography variant="h6">
                                There's no data to show for the selected date range
                            </Typography>
                        </Grid>
                    }
                    {data?.length > 0 &&
                        <Grid item xs={9} height="80vh" alignItems="center" justifyContent="center">
                            <DataGrid
                                rows={data.map(x => ({ id: x.id, timestamp: new Date(x.timestamp), reading: x.readingId, value: `${x.value} ${x.units ? x.units : ""}` }))}
                                columns={columns}
                                pageSize={100}
                                rowsPerPageOptions={[100]}
                                checkboxSelection
                                disableSelectionOnClick
                                sx={{ backgroundColor: "#afc0d9" }}
                            />
                        </Grid>
                    }
                </>
            </Grid >

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}