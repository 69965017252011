import { HubConnection, HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions, LogLevel } from "@microsoft/signalr";
import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { signalRUrl } from "../../app.config";
import { AppDispatch, RootState } from "../../app/store";
import { INewConfigurationMessage } from "../../models/signalr/INewConfigurationMessage";
import { INewConnectivityStatusMessage } from "../../models/signalr/INewConnectivityStatusMessage";
import { INewGatewayStatusMessage } from "../../models/signalr/INewGatewayStatusMessage";
import { INewMetadataMessage } from "../../models/signalr/INewMetadataMessage";
import { INewReadingsMessage } from "../../models/signalr/INewReadingsMessage";
import { INewUplinkMessage } from "../../models/signalr/INewUplinkMessage";
import { apiSlice } from "../api/apiSlice";
import { localLogout, setCredentials } from "../auth/authSlice";

export const signalRMiddleware = createListenerMiddleware();

let hubConnection: HubConnection = undefined;
signalRMiddleware.startListening({
    matcher: isAnyOf(
        setCredentials,
        apiSlice.endpoints.getAllDevices.matchFulfilled,
        apiSlice.endpoints.addDevice.matchFulfilled,
        apiSlice.endpoints.getAllGateways.matchFulfilled,
        apiSlice.endpoints.addGateway.matchFulfilled,
        localLogout
    ),
    effect: async (action, listenerApi) => {
        if (isAnyOf(
            setCredentials,
            apiSlice.endpoints.getAllDevices.matchFulfilled,
            apiSlice.endpoints.getAllGateways.matchFulfilled)(action)) {

            if (!hubConnection || hubConnection.state === HubConnectionState.Disconnected) {
                const options: IHttpConnectionOptions = {
                    headers: {
                        "X-Custom-Auth": `Bearer ${(listenerApi.getState() as RootState).auth.accessToken}`
                    }
                };

                hubConnection = new HubConnectionBuilder()
                    .withUrl(signalRUrl, options)
                    .configureLogging(LogLevel.Information)
                    .build();

                hubConnection.on("newReadings", (message: INewReadingsMessage) => {
                    (listenerApi.dispatch as AppDispatch)(
                        apiSlice.util.updateQueryData('getAllDevices', undefined, (devices) => {
                            const device = devices.find(x => x.id === message.deviceId);

                            device.sensors.forEach((sensor) => {
                                sensor.readings.forEach((reading) => {
                                    const newReading = message.readings[`${sensor.id}-${reading.id}`];
                                    if (newReading) {
                                        reading.value = newReading.value;
                                        reading.timestamp = newReading.date;
                                    }
                                })
                            });

                            return devices;
                        })
                    );
                });

                hubConnection.on("newConnectivityStatus", (message: INewConnectivityStatusMessage) => {
                    (listenerApi.dispatch as AppDispatch)(
                        apiSlice.util.updateQueryData('getAllDevices', undefined, (devices) => {
                            const device = devices.find(x => x.id === message.deviceId);

                            device.sensors.forEach((sensor) => {
                                if (message.connectivityStatus.hasOwnProperty(sensor.id)) {
                                    sensor.isOnline = message.connectivityStatus[sensor.id];
                                }
                            });

                            return devices;
                        })
                    );
                });

                hubConnection.on("newConfiguration", (message: INewConfigurationMessage) => {
                    (listenerApi.dispatch as AppDispatch)(
                        apiSlice.util.updateQueryData('getAllDevices', undefined, (devices) => {
                            const device = devices.find(x => x.id === message.deviceId);

                            device.configuration.forEach((config) => {
                                const newConfiguration = message.deviceConfiguration[`${config.id}`];
                                if (newConfiguration) {
                                    config.params.forEach((field) => {
                                        const newField = newConfiguration.fieldEntries.find(x => x.id === field.id);
                                        if (newField) {
                                            field.value = newField.value;
                                        }
                                    })
                                }
                            });

                            device.sensors.forEach((sensor) => {
                                sensor.configuration.forEach((config) => {
                                    const newConfiguration = message.sensorsConfiguration[`${sensor.id}-${config.id}`];
                                    if (newConfiguration) {
                                        config.params.forEach((field) => {
                                            const newField = newConfiguration.fieldEntries.find(x => x.id === field.id);
                                            if (newField) {
                                                field.value = newField.value;
                                            }
                                        })
                                    }
                                })
                            });

                            return devices;
                        })
                    );
                });

                hubConnection.on("newMetadata", (message: INewMetadataMessage) => {
                    (listenerApi.dispatch as AppDispatch)(
                        apiSlice.util.updateQueryData('getAllDevices', undefined, (devices) => {
                            const device = devices.find(x => x.id === message.deviceId);

                            Object.keys(message.deviceMetadata).forEach(metaKey => {
                                device.metadata[metaKey] = message.deviceMetadata[metaKey];
                            });

                            Object.keys(message.sensorsMetadata).forEach(sensorId => {
                                const sensor = device.sensors.find(x => x.id === sensorId);

                                Object.keys(message.sensorsMetadata[sensorId]).forEach(metaKey => {
                                    sensor.metadata[metaKey] = message.sensorsMetadata[sensorId][metaKey];
                                });
                            });

                            return devices;
                        })
                    );
                });

                hubConnection.on('newUplink', (message: INewUplinkMessage) => {
                    (listenerApi.dispatch as AppDispatch)(
                        apiSlice.util.updateQueryData('getAllDevices', undefined, (devices) => {
                            const device = devices.find(x => x.id === message.deviceId);
                            device.lastUplinkData = message.payload;
                            device.lastUplinkDate = message.timestamp;

                            return devices;
                        })
                    )
                });

                hubConnection.on('newGatewayStatus', (message: INewGatewayStatusMessage) => {
                    (listenerApi.dispatch as AppDispatch)(
                        apiSlice.util.updateQueryData('getAllGateways', undefined, (gateways) => {
                            const gateway = gateways.find(x => x.id === message.gatewayId);

                            if (gateway) {
                                gateway.online = message.isOnline;
                                gateway.connectivityDate = message.timestamp;
                            }
                        })
                    )
                });

                hubConnection.start().then(a => {
                    if (hubConnection.connectionId) {
                        listenerApi.dispatch(apiSlice.endpoints.subscribeAll.initiate({
                            connectionId: hubConnection.connectionId
                        }));
                    }
                });
            }
        } else if (isAnyOf(
            apiSlice.endpoints.addDevice.matchFulfilled,
            apiSlice.endpoints.addGateway.matchFulfilled)(action)) {
            if (hubConnection.connectionId) {
                listenerApi.dispatch(apiSlice.endpoints.subscribeAll.initiate({
                    connectionId: hubConnection.connectionId
                }));
            }
        } else if (action.type === localLogout.type) {
            if (hubConnection) {
                hubConnection.stop();
            }
        }
    },
});