import { Warning } from "@mui/icons-material";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DataType } from "../../models/ISensor";

type SensorValueProps = {
    type: DataType;
    name: string;
    value: any;
    units: string;
}

const SensorValue = ({ type, name, value, units }: SensorValueProps) => {
    if (type === DataType.Decimal) {
        return <Typography gutterBottom variant="body1" component="div">{name}: {value ?? "--"} {units}</Typography>
    } else if (type === DataType.Integer) {
        return <Typography gutterBottom variant="body1" component="div">{name}: {value ?? "--"} {units}</Typography>
    } else if (type === DataType.Hex) {
        return <Typography gutterBottom variant="body1" component="div">{name}: {value?.toString(16) ?? "--"} {units}</Typography>
    } else if (type === DataType.Boolean) {
        return <Stack direction="row" spacing={1}>
            {value && <Warning htmlColor="#FF0000" />}
            <Typography gutterBottom variant="body1" component="div" color={value === true ? "#FF0000" : "#000000"}>{value ? "Alarm" : "No Alarm"}</Typography>
        </Stack>
    } else if (type === DataType.Gps) {
        return <Stack direction="column">
                    <Typography gutterBottom variant="body1" component="div">Lat: {value?.lat ?? "--"} {units}</Typography>
                    <Typography gutterBottom variant="body1" component="div">Lon: {value?.lon ?? "--"} {units}</Typography>
                </Stack>
    }
}

export default SensorValue;