import { ListAlt, Settings } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, FormControlLabel, Grid, IconButton, Stack, Switch, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IDevice } from "../../models/IDevice";
import SensorValue from "./SensorValue";

type DeviceProps = {
    device: IDevice;
    showActions: Boolean;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

const Device = ({ device, showActions, onEditClick, onDeleteClick }: DeviceProps) => {
    const navigate = useNavigate();
    const [showDebug, setShowDebug] = useState(false);

    const handleDebugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowDebug(event.target.checked);
    };

    return (
        <Card sx={{ padding: 2 }}>
            <CardHeader
                title={device.name}
                titleTypographyProps={{ fontWeight: 'bold' }}
                subheader={device.description}
                action={
                    <Stack direction="row">
                        <FormControlLabel control={<Switch checked={showDebug} onChange={handleDebugChange} />} label="Debug" />
                        {(device.commands?.length > 0 || device.configuration?.length > 0) &&
                            <IconButton onClick={() => navigate(`/devices/${device.id}/configuration`)}>
                                <Settings />
                            </IconButton>
                        }
                    </Stack>
                }
            />

            <CardContent>
                <Grid container spacing={2} direction="row" alignItems="left" justifyContent="space-between" width='100%'>
                    <Grid item xs={6}>
                        <Stack>
                            <Typography gutterBottom fontWeight='bold' variant="h6" component="div">
                                Device Id
                            </Typography>
                            <Typography variant="body1" component="div">
                                {device.id}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack>
                            <Typography gutterBottom fontWeight='bold' variant="h6" component="div">
                                Last uplink
                            </Typography>
                            <Typography variant="body1" component="div">
                                {new Date(device.lastUplinkDate).toLocaleString()}
                            </Typography>
                        </Stack>
                    </Grid>
                    {showDebug &&
                        <Grid item xs={6}>
                            <Stack>
                                <Typography gutterBottom fontWeight='bold' variant="h6" component="div">
                                    Last uplink payload (Base 64)
                                </Typography>
                                <Typography variant="body1" component="div">
                                    {device.lastUplinkData ?? "NOT AVAILABLE"}
                                </Typography>
                            </Stack>
                        </Grid>
                    }
                    {showDebug && Object.keys(device.metadata).length > 0 &&
                        Object.keys(device.metadata).map(key =>
                            <Grid key={key} item xs={6}>
                                <Stack>
                                    <Typography gutterBottom fontWeight='bold' variant="h6" component="div">
                                        {key[0].toUpperCase()}{key.slice(1)}
                                    </Typography>
                                    <Typography variant="body1" component="div">
                                        {device.metadata[key]}
                                    </Typography>
                                </Stack>
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <Divider variant="middle" />
                    </Grid>
                    {[...device.sensors].sort((s1, s2) => s1.name.localeCompare(s2.name)).map(sensor =>
                        <Grid key={sensor.id} item xs={6}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography fontWeight='bold' variant="body1" component="div" color={sensor.isOnline === true ? "#000000" : "#FF0000"}>
                                    {sensor.name} ({sensor.isOnline ? "Online" : "Offline"})
                                </Typography>

                                <Box>
                                    <IconButton onClick={() => navigate(`/devices/${device.id}/${sensor.id}/history`)}>
                                        <ListAlt />
                                    </IconButton>
                                    { sensor.configuration?.length > 0 &&
                                        <IconButton onClick={() => navigate(`/devices/${device.id}/${sensor.id}/configuration`)}>
                                            <Settings />
                                        </IconButton>
                                    }
                                </Box>
                            </Stack>
                            {showDebug && Object.keys(sensor.metadata).length > 0 &&
                                Object.keys(sensor.metadata).map(key =>
                                    <Typography key={key} gutterBottom variant="body1" component="div">
                                        {key[0].toUpperCase()}{key.slice(1)}: {sensor.metadata[key]}
                                    </Typography>
                                )
                            }
                            {sensor.readings.map(reading =>
                                <Fragment key={reading.id}>
                                    {
                                        (showDebug || !reading.isDebug) &&
                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                            <SensorValue type={reading.type} name={reading.name} value={reading.value} units={reading.units} />
                                            <Typography gutterBottom variant="body2" component="div">
                                                {reading.timestamp ? new Date(reading.timestamp).toLocaleString() : "NO DATE"}
                                            </Typography>
                                        </Stack>
                                    }
                                </Fragment>
                            )}
                        </Grid>
                    )
                    }
                </Grid>
            </CardContent>
            {showActions &&
                <CardActions sx={{ justifyContent: "end" }}>
                    <Button size="large" color="primary" variant="outlined" onClick={onEditClick}>
                        Edit
                    </Button>
                    <Button size="large" color="error" variant="outlined" onClick={onDeleteClick}>
                        Delete
                    </Button>
                </CardActions>
            }
        </Card>
    )
};

export default Device;