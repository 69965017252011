import { SearchOutlined } from "@mui/icons-material";
import { Stack, TextField, IconButton, Button, CircularProgress, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import Device from "../../components/Device/Device";
import AddDeviceDialog from "../../components/Dialogs/Devices/AddDeviceDialog";
import UpdateDeviceDialog from "../../components/Dialogs/Devices/UpdateDeviceDialog";
import GenericDeleteDialog from "../../components/Dialogs/GenericDeleteDialog";
import { useAddDeviceMutation, useDeleteDeviceMutation, useGetAllDevicesQuery, useGetAllUsersQuery, useUpdateDeviceMutation } from "../../features/api/apiSlice";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { IDevice } from "../../models/IDevice";
import { IUser, UserRole } from "../../models/IUser";
import { LoraWanDeviceSpecOtaaV1_0_X, LoraWanDeviceSpecOtaaV1_1, LoraWanDeviceSpecAbpV1_0_X, LoraWanDeviceSpecAbpV1_1 } from "../../models/requests/devices/IAddDeviceRequest";
import { useAppSelector } from '../../app/hooks';

export default function DevicesPage() {
    const authUser = useAppSelector(selectCurrentUser);

    const [filter, setFilter] = useState<string>("");
    const [selectableUsers, setSelectableUsers] = useState<IUser[]>([]);

    const { data: users } = useGetAllUsersQuery();
    const { data: devices, isLoading, isFetching } = useGetAllDevicesQuery();

    const [addDevice, addDeviceResult] = useAddDeviceMutation();
    const [updateDevice, updateDeviceResult] = useUpdateDeviceMutation();
    const [deleteDevice, deleteDeviceResult] = useDeleteDeviceMutation();

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

    const handleAddDevice = (name: string, description: string | null, devEui: string, otaaV1_0_X: LoraWanDeviceSpecOtaaV1_0_X | null, otaaV1_1: LoraWanDeviceSpecOtaaV1_1 | null, abpV1_0_X: LoraWanDeviceSpecAbpV1_0_X | null, abpV1_1: LoraWanDeviceSpecAbpV1_1 | null, deviceType: string, ownerId?: string | null) => {
        setOpenAddDialog(false);
        addDevice({name, description, devEui, otaaV1_0_X, otaaV1_1, abpV1_0_X, abpV1_1, deviceType, ownerId});
    }

    const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null);
    const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

    const handleFilterChange: (event: any) => void = (event) => {
        setFilter(event.target.value);
    };

    const handleEditClick = (device: IDevice) => {
        setSelectedDevice(device);
        setOpenUpdateDialog(true);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
        setSelectedDevice(null);
    };

    const handleUpdateDevice = (deviceId: string, name: string, description: string) => {
        setOpenUpdateDialog(false);
        updateDevice({deviceId, updateDevice: {ownerId: selectedDevice.ownerId, name, description}})
    };

    const handleDeleteClick = (device: IDevice) => {
        setSelectedDevice(device);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedDevice(null);
    };

    const handleDeleteDevice = (deviceId: string) => {
        setOpenDeleteDialog(false);
        deleteDevice({deviceId, deleteDevice: {ownerId: selectedDevice.ownerId}})
    };

    useEffect(() => {
        if (authUser.role === UserRole.SuperAdmin && users) {
            setSelectableUsers([...users].filter(u => u.role === UserRole.Admin))
        }
    }, [users, authUser.role]);

    return (
        <>
            <Grid container display="flex" spacing={1} direction="row" alignItems="center" justifyContent="center" width='100%' sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={12} sm={12} md={9} sx={{ ml: 1 }} >
                    <Typography variant="h4">
                        Devices
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} sx={{ ml: 1 }} >
                    <Stack direction="row">
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="Search filter"
                            InputProps={{
                                startAdornment: (
                                    <IconButton>
                                        <SearchOutlined />
                                    </IconButton>
                                ),
                            }}
                            value={filter}
                            onChange={handleFilterChange}
                        />
                        {authUser.role !== UserRole.User &&
                            <Button variant="contained" onClick={() => setOpenAddDialog(true)} sx={{ ml: 1, mt: 1, mb: 1 }}>Add Device</Button>
                        }
                    </Stack>
                </Grid>
                <>
                    {devices?.length === 0 &&
                        <Grid item display="flex" xs={12} minHeight="70vh" alignItems="center" justifyContent="center">
                            <Typography variant="h6">
                                You don't have any devices
                            </Typography>
                        </Grid>
                    }
                    {devices?.length > 0 &&
                        devices
                            .filter((dv) => dv.name.includes(filter) ||
                                dv.description.includes(filter) ||
                                dv.id.includes(filter))
                            .map((dv) =>
                                <Grid key={dv.id} item xs={12} sm={9} sx={{ ml: 1 }}>
                                    <Device device={dv} showActions={authUser.role !== UserRole.User} onEditClick={() => handleEditClick(dv)} onDeleteClick={() => handleDeleteClick(dv)} />
                                </Grid>
                            )
                    }
                </>
            </Grid >

            {openAddDialog && <AddDeviceDialog selectableUsers={selectableUsers} openDialog={openAddDialog} handleClose={() => setOpenAddDialog(false)} handleAddDevice={handleAddDevice} />}

            {openUpdateDialog && <UpdateDeviceDialog openDialog={openUpdateDialog} handleClose={handleCloseUpdateDialog} device={selectedDevice} handleUpdateDevice={handleUpdateDevice} />}

            {openDeleteDialog &&
                <GenericDeleteDialog
                    openDialog={openDeleteDialog}
                    handleClose={handleCloseDeleteDialog}
                    title={`Do you want to delete ${selectedDevice.name} widh Id ${selectedDevice.id} ?`}
                    message="This action cannot be undone and you will need to create and configure the Device again. All historic data will be lost."
                    handleDelete={() => handleDeleteDevice(selectedDevice.id)}
                />
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading || (isFetching && devices?.length === 0) || addDeviceResult.isLoading || updateDeviceResult.isLoading || deleteDeviceResult.isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}